import styled            from '@emotion/styled';
import { css }           from '@emotion/react';
import { readableColor } from 'polished';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${props => props.thumbnailWidth}, 1fr));
  grid-gap: 15px;
  
  @media (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  > button {
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: #454545;

  &.int-btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
  }

  &.int-btn-prev {
    font-size: 25px;
  }

  &.int-btn-next {
    font-size: 25px;
  }
`;

export const Counter = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const dialogOverlay = css`
  &[data-reach-dialog-overlay] {
    // @reach styles
    background: hsla(0, 0%, 0%, 0.33);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    // custom styles
    z-index: 1102;
    background: ${props => props.overlayBgColor};
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 20px;
    color: ${props => readableColor(props.overlayBgColor, 'rgba(0,0,0, 0.7)', 'rgba(255,255,255, 0.7)')};

    svg {
      fill: ${props => readableColor(props.overlayBgColor, 'rgba(0,0,0, 0.7)', 'rgba(255,255,255, 0.7)')};
    }
  }
`;

export const dialogContent = css`
  &[data-reach-dialog-content] {
    // @reach styles
    outline: none;

    // custom styles
    position: relative;
    z-index: 1103;
    background: none;
    padding: 0;
    margin: auto;
    width: auto;

    img {
      box-shadow: 0 10px 50px hsla(0, 0%, 0%, 0.33);
      margin: 0;
      max-height: 90vh;
    }
  }
`;
